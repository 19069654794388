import {PlatformAppConfig} from "../_interfaces/platform";

export const getWhiteLabelResourcePrefix = (
  config: PlatformAppConfig
) => {
  return `${config.app_domain_protocol}://${config.app_domain_url}/app/_agencies/${config.app_domain_name.toLowerCase()}`;
}

export const getWhiteLabelResource = (
  resource: string,
  prefix: string | PlatformAppConfig
) => {
  resource = resource.startsWith('/') ? resource.substring(1) : resource;
  const pfx = (typeof prefix === 'string' ? prefix : getWhiteLabelResourcePrefix(prefix));
  return `${pfx}/${resource}`;
}
