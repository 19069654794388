import {Component, effect, inject, OnInit, Renderer2} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {RouterOutlet} from "@angular/router";
import {HotjarService} from "@app/shared/_services/hotjar.service";
import {EmailchefService} from "@app/shared/_services/emailchef.service";
import {map} from "rxjs";
import {environment} from "@environments/environment";
import {FlowsBuilderService} from "@app/features/editor/builder.service";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `,
  standalone: true,
  imports: [
    RouterOutlet
  ]
})
export class AppComponent implements OnInit {

  private primengConfig = inject(PrimeNGConfig);
  private hotjarService = inject(HotjarService);
  private emailchefService = inject(EmailchefService);
  private renderer = inject(Renderer2);
  private flowBuilderService = inject(FlowsBuilderService);
  private document = inject(DOCUMENT);

  addIcons$ = effect(() => {
    const {favicon} = this.flowBuilderService.agencyConfig();
    Object.entries(favicon).forEach(([faviconType, resourceUrl]) => {

      let icon : any = {
          href: resourceUrl,
          rel: 'icon'
      };

      if (faviconType === 'icon_180x180'){
        icon.rel = 'apple-touch-icon';
      }

      if (faviconType === 'icon_svg'){
        icon.type = 'image/svg+xml';
      }

      let [_, size] = faviconType.split("_");

      if (size && size !== 'svg'){
        if (size === 'default'){
          size = '32x32'
        }
        icon.sizes = size;
      }

      const linkElement = this.renderer.createElement('link');
      this.renderer.setAttribute(linkElement, 'rel', icon.rel);
      this.renderer.setAttribute(linkElement, 'href', icon.href);
      if (icon.sizes) {
        this.renderer.setAttribute(linkElement, 'sizes', icon.sizes);
      }
      if (icon.type) {
        this.renderer.setAttribute(linkElement, 'type', icon.type);
      }
      this.renderer.appendChild(this.document.head, linkElement);
    });
  });

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.primengConfig.zIndex = {
      ...this.primengConfig.zIndex,
      modal: 1105
    }
    this.emailchefService.getAccountInfo()
      .pipe(
        map(res => res.data)
      ).subscribe({
      next: ({account_id}) => {
        if (environment?.hotjar?.id && environment?.hotjar?.sv) {
          const account_ids = environment?.hotjar?.account_ids || [];
          if (account_ids.includes(+account_id)){
            this.hotjarService.initHotjar();
          }
        }
      }
    });
  }

}
