import {Injectable, signal} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {FrontendFlow} from "@app/shared/_interfaces/flows";
import {FlowAgencyConfig} from "@app/shared/_interfaces/platform";

@Injectable({
  providedIn: 'root'
})
export class FlowsBuilderService {

  private _validateLoading = new BehaviorSubject<boolean>(false);
  readonly validateLoading$ = this._validateLoading.asObservable();

  private _saveLoading = new BehaviorSubject<boolean>(false);
  readonly saveLoading$ = this._saveLoading.asObservable();

  private _dateInterval = new BehaviorSubject<null | [string, string]>(null);
  readonly dateInterval$ = this._dateInterval.asObservable();

  flow = signal<FrontendFlow>(null);

  private _flowLoaded = new BehaviorSubject<boolean>(false);
  readonly flowLoaded$ = this._flowLoaded.asObservable();

  agencyConfig = signal<FlowAgencyConfig>({
    logo: '/assets/images/favicon/icon.svg',
    name: 'Emailchef',
    favicon: {
      icon_default: '/assets/images/favicon/favicon.ico',
      icon_svg: '/assets/images/favicon/favicon.svg',
      icon_192x192: '/assets/images/favicon/icon-192.png',
      icon_512x512: '/assets/images/favicon/icon-512.png',
      icon_180x180: '/assets/images/favicon/apple-touch-icon.png'
    }
  });

  setFlowLoaded(
    loading: boolean
  ){
    this._flowLoaded.next(loading);
  }

  validateLoading(loading: boolean){
    this._validateLoading.next(loading);
  }


  saveLoading(loading: boolean, globalLoading?: boolean){
    if (globalLoading){
      this._flowLoaded.next(!loading);
    } else {
      this._saveLoading.next(loading);
    }
  }

  setDateInterval(interval: null | [string, string]){
    this._dateInterval.next(interval);
    if (interval[0] == "-1" && interval[1] == "-1"){
      this._dateInterval.next(null);
    }
  }

  setFlow(flow: FrontendFlow){
    this.flow.set(flow);
  }

}
